import { Controller } from "@hotwired/stimulus";

// data-controller="form--input-warning"
// data-form--input-warning-target="{{Insert target name here}}"
// data-form--input-warning-{{Insert value name here}}-value="{{Insert value here}}"
// data-action="{{Insert event here}}->form--input-warning#{{Insert function name here}}"
export default class InputWarningController extends Controller {
  static targets = ["message", "template"];

  show() {
    const content = this.templateTarget.content.cloneNode(true);
    this.messageTarget.appendChild(content);
  }

  hide() {
    this.messageTarget.innerHTML = "";
  }
}
