import c0 from './add_class_controller';
import c1 from './add_templated_content_controller';
import c2 from './analytics_controller';
import c3 from './announce_auto_suggestions_controller';
import c4 from './announce_title_controller';
import c5 from './auto_suggest_controller';
import c6 from './auto_suggest_list_controller';
import c7 from './browser_fingerprint_controller';
import c8 from './cancellation_reasons_controller';
import c9 from './charts/balance_history_chart_controller';
import c10 from './charts/bank_account_balance_chart_controller';
import c11 from './charts/bank_accounts_summary_chart_controller';
import c12 from './charts/benchmarking_report_chart_controller';
import c13 from './charts/cashflow_chart_controller';
import c14 from './charts/cashflow_horizon_chart_controller';
import c15 from './charts/core/bar_chart_controller';
import c16 from './charts/core/hybrid_bar_line_chart_controller';
import c17 from './charts/core/line_chart_controller';
import c18 from './charts/invoice_timeline_chart_controller';
import c19 from './chat/iframe_controller';
import c20 from './chat/zendesk_controller';
import c21 from './checkbox_list_controller';
import c22 from './collapse_controller';
import c23 from './content_loader_controller';
import c24 from './copy_to_clipboard_controller';
import c25 from './credit_calculator_controller';
import c26 from './dashboard/tabs_controller';
import c27 from './date_picker_controller';
import c28 from './date_picker_warning_controller';
import c29 from './disable_link_on_click_controller';
import c30 from './disclosure_v2_controller';
import c31 from './dispatch_event_controller';
import c32 from './dropdown_controller';
import c33 from './dynamic_form_v3_controller';
import c34 from './fixed_auto_suggest_controller';
import c35 from './flash_message_controller';
import c36 from './form/autogrow_controller';
import c37 from './form/clear_child_inputs_controller';
import c38 from './form/colour_field_controller';
import c39 from './form/country_select_controller';
import c40 from './form/destroy_on_match_controller';
import c41 from './form/disable_on_match_controller';
import c42 from './form/disable_on_submit_controller';
import c43 from './form/disable_submitter_controller';
import c44 from './form/file_input_controller';
import c45 from './form/input_warning_controller';
import c46 from './form/on_match_controller';
import c47 from './form/restore_focus_controller';
import c48 from './form/submission_controller';
import c49 from './form/submit_onchange_controller';
import c50 from './lazy_load_image_controller';
import c51 from './load_more_results_controller';
import c52 from './modal_controller';
import c53 from './modal_dismissal_controller';
import c54 from './modal_to_confirm_controller';
import c55 from './multi_select_checkboxes_controller';
import c56 from './navigation/navigation_controller';
import c57 from './navigation/radar_tabs_controller';
import c58 from './navigation/search_controller';
import c59 from './navigation/sidebar_controller';
import c60 from './nested_form_controller';
import c61 from './new_item_quantity_controller';
import c62 from './page_header_controller';
import c63 from './password_strength_meter_controller';
import c64 from './permissions/arrow_controller';
import c65 from './permissions/description_controller';
import c66 from './permissions/detail_controller';
import c67 from './permissions/slider_controller';
import c68 from './practice_client_churn_reasons_controller';
import c69 from './radar/bell_controller';
import c70 from './radar/event_controller';
import c71 from './radar/item_controller';
import c72 from './recurring_warning_controller';
import c73 from './refresh_controller';
import c74 from './register_password_controller';
import c75 from './reload_frame_controller';
import c76 from './remove_element_controller';
import c77 from './scroll_to_controller';
import c78 from './select_as_link_controller';
import c79 from './sortable_controller';
import c80 from './table/expandable_row_controller';
import c81 from './toggle_hidden_controller';
import c82 from './truncated_text_controller';
import c83 from './turbo_confirm_controller';
import c84 from './turbo_frame/polling_controller';
import c85 from './turbo_frame/reload_on_error_controller';
import c86 from './turbo_stream_controller';
import c87 from './turbo_stream_polling_controller';
import c88 from './two_dp_controller';
import c89 from './web_console_controller';
export const definitions = [
	{identifier: 'add-class', controllerConstructor: c0},
	{identifier: 'add-templated-content', controllerConstructor: c1},
	{identifier: 'analytics', controllerConstructor: c2},
	{identifier: 'announce-auto-suggestions', controllerConstructor: c3},
	{identifier: 'announce-title', controllerConstructor: c4},
	{identifier: 'auto-suggest', controllerConstructor: c5},
	{identifier: 'auto-suggest-list', controllerConstructor: c6},
	{identifier: 'browser-fingerprint', controllerConstructor: c7},
	{identifier: 'cancellation-reasons', controllerConstructor: c8},
	{identifier: 'charts--balance-history-chart', controllerConstructor: c9},
	{identifier: 'charts--bank-account-balance-chart', controllerConstructor: c10},
	{identifier: 'charts--bank-accounts-summary-chart', controllerConstructor: c11},
	{identifier: 'charts--benchmarking-report-chart', controllerConstructor: c12},
	{identifier: 'charts--cashflow-chart', controllerConstructor: c13},
	{identifier: 'charts--cashflow-horizon-chart', controllerConstructor: c14},
	{identifier: 'charts--core--bar-chart', controllerConstructor: c15},
	{identifier: 'charts--core--hybrid-bar-line-chart', controllerConstructor: c16},
	{identifier: 'charts--core--line-chart', controllerConstructor: c17},
	{identifier: 'charts--invoice-timeline-chart', controllerConstructor: c18},
	{identifier: 'chat--iframe', controllerConstructor: c19},
	{identifier: 'chat--zendesk', controllerConstructor: c20},
	{identifier: 'checkbox-list', controllerConstructor: c21},
	{identifier: 'collapse', controllerConstructor: c22},
	{identifier: 'content-loader', controllerConstructor: c23},
	{identifier: 'copy-to-clipboard', controllerConstructor: c24},
	{identifier: 'credit-calculator', controllerConstructor: c25},
	{identifier: 'dashboard--tabs', controllerConstructor: c26},
	{identifier: 'date-picker', controllerConstructor: c27},
	{identifier: 'date-picker-warning', controllerConstructor: c28},
	{identifier: 'disable-link-on-click', controllerConstructor: c29},
	{identifier: 'disclosure-v2', controllerConstructor: c30},
	{identifier: 'dispatch-event', controllerConstructor: c31},
	{identifier: 'dropdown', controllerConstructor: c32},
	{identifier: 'dynamic-form-v3', controllerConstructor: c33},
	{identifier: 'fixed-auto-suggest', controllerConstructor: c34},
	{identifier: 'flash-message', controllerConstructor: c35},
	{identifier: 'form--autogrow', controllerConstructor: c36},
	{identifier: 'form--clear-child-inputs', controllerConstructor: c37},
	{identifier: 'form--colour-field', controllerConstructor: c38},
	{identifier: 'form--country-select', controllerConstructor: c39},
	{identifier: 'form--destroy-on-match', controllerConstructor: c40},
	{identifier: 'form--disable-on-match', controllerConstructor: c41},
	{identifier: 'form--disable-on-submit', controllerConstructor: c42},
	{identifier: 'form--disable-submitter', controllerConstructor: c43},
	{identifier: 'form--file-input', controllerConstructor: c44},
	{identifier: 'form--input-warning', controllerConstructor: c45},
	{identifier: 'form--on-match', controllerConstructor: c46},
	{identifier: 'form--restore-focus', controllerConstructor: c47},
	{identifier: 'form--submission', controllerConstructor: c48},
	{identifier: 'form--submit-onchange', controllerConstructor: c49},
	{identifier: 'lazy-load-image', controllerConstructor: c50},
	{identifier: 'load-more-results', controllerConstructor: c51},
	{identifier: 'modal', controllerConstructor: c52},
	{identifier: 'modal-dismissal', controllerConstructor: c53},
	{identifier: 'modal-to-confirm', controllerConstructor: c54},
	{identifier: 'multi-select-checkboxes', controllerConstructor: c55},
	{identifier: 'navigation--navigation', controllerConstructor: c56},
	{identifier: 'navigation--radar-tabs', controllerConstructor: c57},
	{identifier: 'navigation--search', controllerConstructor: c58},
	{identifier: 'navigation--sidebar', controllerConstructor: c59},
	{identifier: 'nested-form', controllerConstructor: c60},
	{identifier: 'new-item-quantity', controllerConstructor: c61},
	{identifier: 'page-header', controllerConstructor: c62},
	{identifier: 'password-strength-meter', controllerConstructor: c63},
	{identifier: 'permissions--arrow', controllerConstructor: c64},
	{identifier: 'permissions--description', controllerConstructor: c65},
	{identifier: 'permissions--detail', controllerConstructor: c66},
	{identifier: 'permissions--slider', controllerConstructor: c67},
	{identifier: 'practice-client-churn-reasons', controllerConstructor: c68},
	{identifier: 'radar--bell', controllerConstructor: c69},
	{identifier: 'radar--event', controllerConstructor: c70},
	{identifier: 'radar--item', controllerConstructor: c71},
	{identifier: 'recurring-warning', controllerConstructor: c72},
	{identifier: 'refresh', controllerConstructor: c73},
	{identifier: 'register-password', controllerConstructor: c74},
	{identifier: 'reload-frame', controllerConstructor: c75},
	{identifier: 'remove-element', controllerConstructor: c76},
	{identifier: 'scroll-to', controllerConstructor: c77},
	{identifier: 'select-as-link', controllerConstructor: c78},
	{identifier: 'sortable', controllerConstructor: c79},
	{identifier: 'table--expandable-row', controllerConstructor: c80},
	{identifier: 'toggle-hidden', controllerConstructor: c81},
	{identifier: 'truncated-text', controllerConstructor: c82},
	{identifier: 'turbo-confirm', controllerConstructor: c83},
	{identifier: 'turbo-frame--polling', controllerConstructor: c84},
	{identifier: 'turbo-frame--reload-on-error', controllerConstructor: c85},
	{identifier: 'turbo-stream', controllerConstructor: c86},
	{identifier: 'turbo-stream-polling', controllerConstructor: c87},
	{identifier: 'two-dp', controllerConstructor: c88},
	{identifier: 'web-console', controllerConstructor: c89},
];
